<!--
 * @Author: lcz
 * @Date: 2023-01-09 15:49:24
 * @LastEditors: Please set LastEditors
 * @LastEditTime: 2024-04-29 11:10:29
 * @Description: 后买单——确认订单弹框
-->
<template>
  <div class="dialog-wrapper">
    <!-- 确认订单弹框-->
    <dialog-box title="确认订单归属" :show="orderConfirmShow" rightBtnText="确定" @onRightClick="orderConfirm">
      <div slot="body">
        <div class="confirm-box">
          <div class="row">
            <div class="row-left">请选择本单为：</div>
          </div>
          <div class="row" @click="changeSelectStatus('1')">
            <div class="row-left">
              <i :class="selectStatus === '1' ? 'iconfont iconxuanzhong' : 'iconfont iconweixuanzhong'"></i>
              <!--外带且没有桌号-->
              <template v-if="serviceType===2&&!tableName">
                <span>属于首单</span>
              </template>
              <template v-else>
                <span>属于桌号</span>
                <span class="color-warning">#</span>
                <span class="table-name">{{ tableName }}</span>
                <span>的首单</span>
              </template>
            </div>
          </div>
          <div class="row" @click="changeSelectStatus('2')">
            <div class="row-left">
              <i :class="selectStatus === '2' ? 'iconfont iconxuanzhong' : 'iconfont iconweixuanzhong'"></i>
              <span>属于</span>
            </div>
            <div class="row-right">
              <div class="select-box" @click="showTableSelect">
                <span v-if="selectTableName" class="select-value">{{ selectTableName }}</span>
                <span v-else class="select-placeholder">请选择桌号</span>
                <i class="iconfont iconfangxiang-xiangyou"></i>
              </div>
              <span class="right-text">的加单</span>
            </div>
          </div>
        </div>
      </div>
    </dialog-box>
    <!--默认加单弹框-->
    <dialog-box title="确认订单归属" :show="defaultMergeShow" showLeftBtn leftBtnText="重新扫桌码" rightBtnText="确定" @onLeftClick="scanTable" @onRightClick="orderConfirm">
      <div slot="body">
        <div class="confirm-box">
          <div class="row">
            <div class="row-left">
              <span>确定后，订单将自动成为桌号</span>
              <span class="color-warning">#</span>
              <span class="table-name">{{ tableName }}</span>
              <span>的加单；</span>
            </div>
          </div>
        </div>
      </div>
    </dialog-box>
    <!-- loading提示 -->
    <loading-box :show="loadingShow"></loading-box>
    <!--        选择桌位-->
    <table-select-popup :show="tableSelectShow" title="选择桌号" :useComponentData="false" storeId :sourceData="tableList" @onClose="tableSelectShow = false" @onSubmit="confirmSelectTable" />
    <!-- 订单选择弹窗 -->
    <popup-box :show="orderPopupShow" position="bottom" popupStyle="height:90%">
      <div class="wh-popup-container">
        <div class="wh-popup-header">
          <div class="wh-popup-header_title">选择订单</div>
        </div>
        <div class="wh-popup-body with-header with-footer">
          <div class="order-list">
            <div v-for="(order, index) in tableOrderList" :key="index" class="order-box">
              <div class="order-info" @click="selectSubOrder(order)">
                <div class="order-info-left">
                  <div class="order-info-row">
                    <span class="text-main margin-right-16">{{ order.orderTime | timeFilter }}</span>
                    <span class="text-normal_gray">{{ order.guestCount || 0 }}位</span>
                  </div>
                  <div class="order-info-row">
                    <span class="text-sub">订单金额：</span>
                    <span class="text-sub_red">{{ order.shouldAmount | amountFilter }}</span>
                  </div>
                  <div class="order-info-row">
                    <span class="text-sub">实付金额：</span>
                    <span class="text-sub">{{ order.actualAmount | amountFilter }}</span>
                  </div>
                </div>
                <div class="order-info-right">
                  <i v-if="mergeId === order.orderId" class="order-select-icon active iconfont iconxuanzhong"></i>
                  <i v-else class="order-select-icon iconfont iconweixuanzhong"></i>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="wh-popup-footer">
          <btn-footer showLeftBtn leftBtnText="返回" rightBtnText="确定" @onLeftClick="closeSubOrderPopup" @onRightClick="confirmSelectSubOrder"></btn-footer>
        </div>
      </div>
    </popup-box>
  </div>
</template>

<script>
import { mapState } from 'vuex'
import dayjs from 'dayjs'
import DialogBox from '@/components/common/DialogBox'
import BtnFooter from '@/components/common/BtnFooter'
import PopupBox from '@/components/common/PopupBox'
import LoadingBox from '@/components/common/LoadingBox'
import TableSelectPopup from '@/components/business/TableSelectPopup'
import { getOrderDetailAfterPayApi, getUnPayOrderListApi } from '@/api/order'
export default {
  name: 'PaymentOrderConfirmDialog',
  components: { DialogBox, BtnFooter, PopupBox, LoadingBox, TableSelectPopup },
  props: {
    show: { type: Boolean, required: true, default: false },
    orderId: { type: String, required: true, default: '' },
    tableId: { type: String, required: true, default: '' },
    tableName: { type: String, required: false, default: '' }
  },
  data() {
    return {
      loadingShow: false,
      orderConfirmShow: false,
      order: {
        items: []
      },
      selectStatus: '',
      selectTableName: '',
      tableSelectShow: false,
      tableList: [],
      selectTable: {},
      tableOrderList: [],
      orderPopupShow: false,
      mergeId: '',
      defaultMergeShow: false
    }
  },
  computed: {
    ...mapState('order', ['userId', 'storeId', 'serviceType'])
  },
  filters: {
    amountFilter(val) {
      if (!parseFloat(val)) {
        return '￥0.00'
      }
      return '￥' + parseFloat(val).toFixed(2)
    },
    timeFilter(val) {
      if (!val) {
        return ''
      }
      val = val.replace(/-/g, '/')
      return dayjs(val, 'MM-DD HH:mm:ss').format('MM-DD HH:mm')
    }
  },
  watch: {
    show(val) {
      if (val) {
        this.getOrderDetail()
        this.getUnPayOrderList()
      }
    }
  },
  methods: {
    getOrderDetail() {
      getOrderDetailAfterPayApi(this.orderId).then((res) => {
        if (res.status !== 0 || !res.data) {
          this.order = {
            items: []
          }
          return false
        }
        this.order = res.data
      })
    },
    getUnPayOrderList() {
      getUnPayOrderListApi(this.storeId).then((res) => {
        if (res.status !== 0 || !res.data) {
          this.tableList = []
          return false
        }
        this.tableList = res.data
        this.findDefaultOrder()
      })
    },
    /**
     * 当前桌位，是否有未买单订单。如果有默认加单，显示默认加单弹框。如果没有，默认首单，显示选择订单方式的弹框
     */
    findDefaultOrder() {
      this.defaultMergeShow = false
      if (this.tableList && this.tableList.length) {
        this.tableList.forEach((x) => {
          x.tables.forEach((y) => {
            if (y.tableId === this.tableId) {
              this.selectStatus = '2'
              this.mergeId = y.orders[0].orderId
              this.defaultMergeShow = true
            }
          })
        })
      }
      if (!this.defaultMergeShow) {
        this.selectStatus = '1'
        this.orderConfirmShow = true
      }
    },
    changeSelectStatus(status) {
      this.selectStatus = status
    },
    showTableSelect() {
      if (this.tableList && this.tableList.length) {
        this.selectStatus = '2'
        this.tableSelectShow = true
      } else {
        this.$toast('无符合条件的桌号')
      }
    },
    confirmSelectTable(table) {
      if (table.tableId) {
        this.selectStatus = '2'
        this.selectTable = table
        this.tableSelectShow = false
        this.tableOrderList = table.orders
        this.orderPopupShow = true
      } else {
        this.$toast('请选择桌号')
      }
    },
    closeSubOrderPopup() {
      this.tableSelectShow = true
      this.orderPopupShow = false
    },
    selectSubOrder(order) {
      this.mergeId = order.orderId
    },
    confirmSelectSubOrder() {
      if (!this.mergeId) {
        this.$toastg('请选择订单')
        return false
      }
      this.selectStatus = '2'
      this.selectTableName = this.selectTable.tableName
      this.orderPopupShow = false
    },
    orderConfirm() {
      if (!this.selectStatus) {
        this.$toast('请确认订单归属')
        return false
      }
      if (this.selectStatus === '2' && !this.mergeId) {
        this.$toast('请选择桌号')
        return false
      }
      if (this.selectStatus === '1') {
        this.mergeId = ''
      }
      this.tableSelectShow = false
      this.orderPopupShow = false
      this.$emit('onConfirm', this.mergeId)
    },
    // 重新扫卓玛
    scanTable() {
      this.tableSelectShow = false
      this.orderPopupShow = false
      this.$emit('scanTable')
    }
  }
}
</script>

<style lang="scss" scoped>
.row {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 20px 0;
  font-size: 28px;

  .row-left {
    text-align: left;
    .iconfont {
      margin-right: 20px;
      font-size: 32px;
      color: $color-text-sub;
    }

    .iconxuanzhong {
      color: $color-primary;
    }
  }

  .row-right {
    display: flex;
    align-items: center;

    .select-box {
      min-width: 220px;
      height: 60px;
      margin-right: 10px;
      padding: 0 10px;
      background-color: $color-background;
      border-radius: 10px;
      display: flex;
      justify-content: space-between;
      align-items: center;

      .select-placeholder {
        color: $color-text-placeholder;
      }

      .select-value {
        color: $color-text-main;
      }

      .iconfont {
        font-size: 32px;
        color: $color-primary;
      }
    }
  }

  .table-name {
    font-size: 30px;
    font-weight: 500;
  }
}
.order-list {
  width: 100%;
  height: 100%;
  overflow-y: scroll;

  .order-box {
    width: 100%;
    margin-bottom: 8px;
    background-color: $color-white;

    .order-info {
      position: relative;
      display: flex;
      width: 100%;
      padding: 26px 24px;
      flex-wrap: nowrap;
      align-items: flex-start;

      &:not(:last-child)::after {
        content: '';
        position: absolute;
        bottom: 0;
        left: 24px;
        right: 24px;
        border-top: 1px dashed #d6d7dc;
      }

      .order-info-left {
        flex: 1;
      }

      .order-info-right {
        flex: 0;
        margin-left: 16px;
        white-space: nowrap;
        text-align: right;
      }

      .order-info-row {
        width: 100%;

        &:not(:last-child):not(.no-marging) {
          margin-bottom: 16px;
        }

        .text-main {
          color: $color-text-main;
          font-size: 30px;
          font-weight: bold;
        }

        .text-sub {
          color: $color-text-main;
          font-size: 28px;
        }

        .text-sub_red {
          color: #fd7022;
          font-size: 28px;
          font-weight: bold;
        }

        .text-normal {
          color: $color-text-normal;
          font-size: 24px;
        }

        .text-normal_gray {
          color: $color-text-sub;
          font-size: 24px;
        }

        .text-normal_blue {
          color: $color-primary;
          font-size: 24px;
        }

        .text-normal_red {
          color: #fd7022;
          font-size: 24px;
        }

        .text-normal_orange {
          color: $color-warning;
          font-size: 24px;
          font-weight: bold;
        }

        .float-right {
          float: right;
        }

        .btn-item {
          display: inline-block;
          padding: 4px 10px;
          font-size: 24px;
          color: $color-primary;
          border: 1px solid $color-primary;
          border-radius: 8px;
        }
      }

      .order-select-icon {
        font-size: 40px;
        color: $color-text-disabled;

        &.active {
          color: $color-primary;
        }
      }
    }
  }
}
</style>
