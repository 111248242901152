<!--
 * @Author: hwu
 * @Date: 2020-11-25 14:37:47
 * @Description: 订单支付页面 -> 单品备注弹窗
 * @LastEditTime: 2022-12-30 09:55:34
-->
<template>
  <div class="wh-popup-wraper" @touchmove.stop>
    <transition name="wh-popup">
      <div class="wh-popup-box" :style="{ height: '90%' }" v-if="show">
        <!-- 备注列表 -->
        <div class="comment-box" v-show="resultBoxShow">
          <div class="comment-header">
            <span class="header-title">商品备注</span>
          </div>
          <div class="comment-body">
            <div class="comment-result">
              <div class="single-comment" v-for="(comment, index) in commentList" :key="index">
                <div class="single-comment_item">
                  <div class="item-info" v-for="(item, itemIndex) in comment.items" :key="itemIndex">
                    <span>{{ item.itemName }}</span>
                  </div>
                </div>
                <div class="single-comment_content">
                  <div class="content-title">备注内容</div>
                  <div class="content-text">{{ comment.mark }}</div>
                </div>
                <div class="single-comment_btn">
                  <div class="btn-item" @click="deleteComment(comment, index)">
                    <span>删除</span>
                    <i class="iconfont iconqingkong"></i>
                  </div>
                </div>
              </div>
              <div class="comment-empty" v-show="!commentList || commentList.length == 0">
                <span>暂无备注内容 ~</span>
              </div>
            </div>
            <btn-footer :showLeftBtn="true" leftBtnText="返回" rightBtnText="新建备注" @onLeftClick="closePopup" @onRightClick="addNewComment"></btn-footer>
          </div>
        </div>
        <!-- 填写备注内容 -->
        <div class="comment-box" v-show="inputBoxShow">
          <div class="comment-header">
            <span class="header-title">请选择或输入备注内容</span>
          </div>
          <div class="comment-body">
            <div class="comment-edit">
              <div class="comment-edit-btn">
                <span class="edit-btn_item" :class="{ active: item.selected === 1 }" v-for="(item, index) in commentInputOptions" :key="index" @click="selectCommentOption(item)">{{ item.value }}</span>
              </div>
              <div class="comment-edit-input">
                <div class="wh-textarea">
                  <textarea class="wh-textarea_input" v-model="currentInput" rows="5" maxlength="60" placeholder="请输入备注内容（限60个字符）"></textarea>
                  <span class="wh-textarea_num">{{ currentInput.length }}/60</span>
                </div>
              </div>
            </div>
          </div>
          <btn-footer :showLeftBtn="true" leftBtnText="返回" rightBtnText="确定" @onLeftClick="closePopup" @onRightClick="confirmInput"></btn-footer>
        </div>
        <!-- 选择备注商品 -->
        <div class="comment-box" v-show="itemBoxShow">
          <div class="comment-header">
            <span class="header-title">请选择需要备注的商品</span>
            <div class="header-btn" @click="selectAllItem()">
              <span>全选</span>
              <i class="iconfont" :class="[allItemSelected ? 'iconduoxuan-xuanzhong active' : 'iconduoxuan-weixuanzhong']"></i>
            </div>
          </div>
          <div class="comment-body">
            <div class="comment-item">
              <div class="item-box" v-for="(item, index) in itemList" :key="index">
                <div class="item-row" @click="selectItem(item)" v-if="!item.subItems">
                  <div class="item-row_info">
                    <div class="item-name">{{ item.itemName }}</div>
                    <div class="item-desc" v-if="item.spec || item.make || item.taste">
                      <span class="item-desc_text" v-if="item.spec">{{ item.spec }}</span>
                      <span class="item-desc_text" v-if="item.make">{{ item.make }}</span>
                      <span class="item-desc_text" v-if="item.taste">{{ item.taste }}</span>
                    </div>
                  </div>
                  <div class="item-row_select" :class="{ active: item.selected }">
                    <i class="iconfont" :class="[item.selected ? 'iconduoxuan-xuanzhong' : 'iconduoxuan-weixuanzhong']"></i>
                  </div>
                </div>
                <div class="unit-box" v-else>
                  <van-collapse v-model="item.active" accordion :border="false">
                    <van-collapse-item :title="item.itemName" value="展开套餐" name="1" :border="false">
                      <div class="item-row" v-for="(sub, subIndex) in item.subItems" :key="subIndex" @click="selectItem(sub)">
                        <div class="item-row_info">
                          <div class="item-name">{{ sub.itemName }}</div>
                          <div class="item-desc" v-if="sub.spec || sub.make || sub.taste">
                            <span class="item-desc_text" v-if="sub.spec">{{ sub.spec }}</span>
                            <span class="item-desc_text" v-if="sub.make">{{ sub.make }}</span>
                            <span class="item-desc_text" v-if="sub.taste">{{ sub.taste }}</span>
                          </div>
                        </div>
                        <div class="item-row_select" :class="{ active: sub.selected }">
                          <i class="iconfont" :class="[sub.selected ? 'iconduoxuan-xuanzhong' : 'iconduoxuan-weixuanzhong']"></i>
                        </div>
                      </div>
                    </van-collapse-item>
                  </van-collapse>
                </div>
              </div>
            </div>
          </div>
          <btn-footer :showLeftBtn="true" leftBtnText="返回" rightBtnText="确定" @onLeftClick="goBackToInput" @onRightClick="submitComment"></btn-footer>
        </div>
      </div>
    </transition>
    <transition name="wh-backdrop">
      <div class="wh-popup-backdrop" @click="onBackdropClick" v-if="show"></div>
    </transition>
  </div>
</template>
<script>
import { Collapse, CollapseItem } from 'vant'
import BtnFooter from '@/components/common/BtnFooter'
import { getSplitCartItemsApi, saveItemMarkApi } from '@/api/order'
import { mapState } from 'vuex'
export default {
  name: 'payment-comment-popup',
  components: { BtnFooter, VanCollapse: Collapse, VanCollapseItem: CollapseItem },
  props: {
    show: { type: Boolean, required: true, default: false },
    orderId: { type: String, required: true, default: '' }
  },
  data () {
    return {
      resultBoxShow: false,
      itemBoxShow: false,
      inputBoxShow: true,
      itemList: [],
      commentList: [],
      commentInputOptions: [
        { key: 1, value: '免葱', selected: 0 },
        { key: 2, value: '免蒜', selected: 0 },
        { key: 3, value: '免香菜', selected: 0 },
        { key: 4, value: '少油', selected: 0 },
        { key: 5, value: '少盐', selected: 0 },
        { key: 6, value: '清真', selected: 0 },
        { key: 7, value: '打包', selected: 0 },
        { key: 8, value: '10分钟后上菜', selected: 0 }
      ],
      currentInput: '',
      allItemSelected: false
    }
  },
  watch: {
    show (val) {
      if (val) {
        this.listOrderItems()
      }
    }
  },
  computed: {
    ...mapState('order', ['userId'])
  },
  created () {
    // this.listOrderItems()
  },
  methods: {
    listOrderItems () {
      getSplitCartItemsApi(this.orderId, this.userId).then(res => {
        if (res.status !== 0 || !res.data) {
          return false
        }
        this.itemList = res.data
        this.initCommentList()
      })
    },
    initCommentList () {
      this.commentList = []
      this.itemList.forEach(item => {
        if (!item.subItems || item.subItems.length === 0) {
          if (item.mark) {
            this.insertCommentItem(item, item.uniKey)
          }
          return true
        }
        item.subItems.forEach(sub => {
          if (sub.mark) {
            this.insertCommentItem(sub, item.uniKey)
          }
        })
      })
      this.commentInputOptions.forEach(item => {
        item.selected = 0
      })
      // 如果没备注过，就显示备注页面，如果之前有过备注了，就显示备注列表
      if (this.commentList.length === 0) {
        this.itemBoxShow = false
        this.inputBoxShow = true
        this.resultBoxShow = false
        this.currentInput = ''
        // 这里将备注状态抛出去，用于改变主页面的备注状态
        this.$emit('onUpdate', false)
      } else {
        this.itemBoxShow = false
        this.inputBoxShow = false
        this.resultBoxShow = true
        // 这里将备注状态抛出去，用于改变主页面的备注状态
        this.$emit('onUpdate', true)
      }
    },
    insertCommentItem (item, parentUniKey) {
      const itemSpecInfo = [item.spec, item.make, item.taste].filter(x => x)
      let itemName = item.itemName
      if (itemSpecInfo && itemSpecInfo.length > 0) {
        itemName += `(${itemSpecInfo.join('、')})`
      }
      const itemInfo = {
        uniKey: item.uniKey,
        parentUniKey: parentUniKey,
        itemName: itemName
      }

      const isExists = this.commentList.some(x => x.mark === item.mark)
      if (isExists) {
        this.commentList.forEach(x => {
          if (x.mark === item.mark) {
            x.items.push(itemInfo)
          }
        })
      } else {
        this.commentList.push({
          mark: item.mark,
          items: [itemInfo]
        })
      }
    },
    deleteComment (comment, index) {
      const commentItems = comment.items.map(x => {
        return {
          uniKey: x.uniKey,
          parentUniKey: x.parentUniKey,
          mark: ''
        }
      })
      saveItemMarkApi(this.orderId, this.userId, commentItems).then(res => {
        if (res.status !== 0) {
          this.$toast(res.msg)
          return false
        }
        this.listOrderItems()
        // this.commentList.splice(index, 1)
        // if (this.commentList.length === 0) {
        //   this.$emit('onUpdate', false)
        // }
      })
    },
    addNewComment () {
      // this.listOrderItems()
      this.itemBoxShow = false
      this.inputBoxShow = true
      this.resultBoxShow = false
      this.currentInput = ''
    },
    selectCommentOption (item) {
      item.selected === 0 ? (item.selected = 1) : (item.selected = 0)
    },
    confirmInput () {
      const selectedOptions = this.commentInputOptions.filter(x => x.selected === 1)
      if (selectedOptions.length <= 0 && !this.currentInput) {
        this.$toast('请填写备注')
        return false
      }
      this.itemBoxShow = true
      this.inputBoxShow = false
      this.resultBoxShow = false
    },
    selectAllItem () {
      this.allItemSelected = !this.allItemSelected
      this.itemList.forEach(item => {
        item.selected = this.allItemSelected
        if (item.subItems && item.subItems.length > 0) {
          item.subItems.forEach(sub => {
            sub.selected = this.allItemSelected
          })
        }
      })
      this.itemList = JSON.parse(JSON.stringify(this.itemList))
    },
    selectItem (item) {
      item.selected = !item.selected
      this.itemList = JSON.parse(JSON.stringify(this.itemList))
      let allItemSelected = true
      this.itemList.forEach(item => {
        if (!item.selected) {
          allItemSelected = false
        }
        if (item.subItems && item.subItems.length > 0) {
          item.subItems.forEach(sub => {
            if (!sub.selected) {
              allItemSelected = false
            }
          })
        }
      })
      this.allItemSelected = allItemSelected
    },
    goBackToInput () {
      this.inputBoxShow = true
      this.resultBoxShow = false
      this.itemBoxShow = false
    },
    submitComment () {
      let comment = ''
      const selectedOption = this.commentInputOptions.filter(x => x.selected === 1)
      if (selectedOption.length > 0) {
        comment = selectedOption.map(option => option.value).join(',')
      }
      if (this.currentInput) {
        comment = comment + ',' + this.currentInput
      }
      let selectedItems = []
      this.itemList.forEach(item => {
        if (!item.subItems || item.subItems.length === 0) {
          if (!item.selected) {
            return true
          }
          selectedItems.push({
            uniKey: item.uniKey,
            parentUniKey: item.uniKey,
            mark: comment
          })
          return true
        }

        item.subItems.forEach(sub => {
          if (!sub.selected) {
            return true
          }
          selectedItems.push({
            uniKey: sub.uniKey,
            parentUniKey: item.uniKey,
            mark: comment
          })
        })
      })
      if (selectedItems.length === 0) {
        this.$toast('请选择要备注的商品')
        return false
      }
      saveItemMarkApi(this.orderId, this.userId, selectedItems).then(res => {
        if (res.status !== 0) {
          this.$toast(res.msg)
          return false
        }
        this.$emit('onUpdate', true)
        this.closePopup()
      })
    },
    onBackdropClick () {},
    closePopup () {
      this.inputBoxShow = false
      this.resultBoxShow = false
      this.itemBoxShow = false
      this.allItemSelected = false
      this.$emit('onClose')
    }
  }
}
</script>
<style lang="scss" scoped>
.wh-popup-box {
  position: fixed;
  bottom: 0;
  left: 0;
  width: 750px;
  height: 100%;
  background-color: $color-white;
  border-radius: 32px 32px 0 0;
  z-index: 2001;
}
.wh-popup-backdrop {
  position: fixed;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  opacity: 0.5;
  background: #000;
  z-index: 2000;
}
.wh-popup-enter-active,
.wh-popup-leave-active {
  transition: transform 0.3s;
}
.wh-popup-enter,
.wh-popup-leave-to {
  transform: translateY(100%);
}
.wh-backdrop-enter-active,
.wh-backdrop-leave-active {
  transition: opacity 0.3s;
}
.wh-backdrop-enter,
.wh-backdrop-leave-to {
  opacity: 0;
}

.comment-box {
  position: relative;
  width: 100%;
  height: 100%;
  .comment-header {
    position: absolute;
    display: flex;
    top: 0;
    left: 0;
    width: 100%;
    height: 80px;
    padding: 22px 24px ;
    align-items: center;
    flex-wrap: nowrap;
    background-color: $color-white;
    border-radius: 32px 32px 0 0;
    z-index: 2002;
    .header-title {
      flex: 1;
      line-height: 36px;
      font-size: 30px;
      font-weight: bold;
      color: $color-text-main;
      white-space: nowrap;
    }
    .header-btn {
      flex: 0;
      line-height: 40px;
      font-size: 24px ;
      color: $color-text-sub;
      white-space: nowrap;
      i {
        margin-left: 10px;
        color: $color-text-disabled;
        font-size: 40px;
        vertical-align: bottom;
        &.active {
          color: $color-primary;
        }
      }
    }
    &::after {
      content: '';
      position: absolute;
      bottom: 0;
      left: 24px ;
      right: 24px ;
      border-top: 1px solid $color-border;
    }
  }
  .comment-body {
    width: 100%;
    height: 100%;
    padding: 80px 0 100px 0;
    overflow-y: auto;
    // -webkit-overflow-scrolling: touch;
  }
}

.comment-result {
  position: relative;
  width: 100%;
  .single-comment {
    width: 100%;
    padding: 24px ;
    .single-comment_item {
      display: flex;
      width: 100%;
      margin-bottom: 16px;
      align-items: center;
      flex-wrap: wrap;
      .item-info {
        position: relative;
        flex: 0;
        padding-left: 24px ;
        margin-right: 24px ;
        line-height: 36px;
        font-size: 28px;
        font-weight: bold;
        color: $color-text-main;
        white-space: nowrap;
        &::before {
          content: '';
          position: absolute;
          top: 14px ;
          left: 0px;
          width: 8px;
          height: 8px;
          background-color: $color-primary;
          border-radius: 50%;
        }
      }
    }
    .single-comment_content {
      width: 100%;
      border-bottom: 1px solid $color-border;
      .content-title {
        width: 100%;
        margin-bottom: 8px;
        line-height: 36px;
        font-size: 24px ;
        color: $color-text-normal;
      }
      .content-text {
        width: 100%;
        padding-left: 24px ;
        margin-bottom: 24px ;
        line-height: 36px;
        font-size: 24px ;
        color: $color-text-main;
      }
    }
    .single-comment_btn {
      width: 100%;
      margin-top: 10px;
      .btn-item {
        font-size: 26px;
        color: $color-primary;
        display: flex;
        align-items: center;
        justify-content: flex-end;
        i {
          font-size: 32px;
        }
      }
    }
  }
  .comment-empty {
    position: absolute;
    top: 30vh;
    left: 0;
    width: 100%;
    text-align: center;
    font-size: 26px;
    color: $color-text-normal;
  }
}

.comment-edit {
  width: 100%;
  .comment-edit-btn {
    display: flex;
    width: 100%;
    padding: 12px;
    flex-wrap: wrap;
    .edit-btn_item {
      flex: 0 0 auto;
      min-width: 128px;
      height: 56px;
      line-height: 32px;
      padding: 10px 24px ;
      margin: 12px;
      text-align: center;
      font-size: 26px;
      white-space: nowrap;
      // border: 1px solid $color-primary;
      color: $color-text-normal;
      background-color: $color-background;
      border-radius: 56px;
      &.active {
        background-color: $color-primary-bg;
        color: $color-primary;
        border: 1px solid $color-primary;
      }
    }
  }
  .comment-edit-input {
    width: 100%;
    padding: 24px ;
  }
}

.comment-item {
  width: 100%;
  padding: 0 24px ;
  .item-box {
    width: 100%;
    border-bottom: 1px solid $color-border;
    .item-row {
      display: flex;
      width: 100%;
      padding: 24px  0;
      line-height: 40px;
      align-items: flex-start;
      flex-wrap: nowrap;
      .item-row_info {
        flex: 1;
        .item-name {
          line-height: 36px;
          color: $color-text-main;
          font-size: 30px;
        }
        .item-desc {
          width: 100%;
          margin-top: 8px;
          .item-desc_text {
            margin-right: 10px;
            line-height: 30px;
            font-size: 24px ;
            color: $color-text-sub;
          }
        }
      }
      .item-row_select {
        flex: 0;
        color: $color-text-disabled;
        &.active {
          color: $color-primary;
        }
        i {
          font-size: 40px;
          vertical-align: bottom;
        }
      }
    }
    .unit-box {
      width: 100%;
      .item-row {
        border-top: 1px solid $color-border;
      }
      /deep/ .van-collapse {
        .van-collapse-item__title {
          padding: 20px 0;
        }
        .van-collapse-item__content {
          padding: 0 0 0 24px ;
        }
      }
    }
  }
}

.wh-textarea {
  position: relative;
  width: 100%;
  .wh-textarea_input {
    width: 100%;
    padding: 16px;
    font-size: 24px ;
    background-color: $color-background;
    resize: none;
    border: 1px solid $color-border;
    border-radius: 4px ;
  }
  .wh-textarea_num {
    position: absolute;
    right: 16px;
    bottom: 16px;
    font-size: 24px ;
    color: $color-text-sub;
  }
}
</style>
